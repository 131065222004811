import { createSlice } from '@reduxjs/toolkit';

import { LeaderboardType } from 'shared/types/query.interfaces';

import { LEADERBOARD_SLICE_NAME } from './action';
import { getLeaderboardReducer, updateLeaderboardReducer } from './reducers';
import { LeaderboardData } from '../../types/leaderboard.interfaces';

export interface LeaderboardSliceState {
  leaderboardType?: LeaderboardType;
  timeoutRequest: Date | null;
  isLoading: boolean;
  leaderboard: LeaderboardData | null;
}

export const initialState: LeaderboardSliceState = {
  leaderboardType: LeaderboardType.POINTS,
  timeoutRequest: null,
  leaderboard: null,
  isLoading: true
};

const leaderboardSlice = createSlice({
  name: LEADERBOARD_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getLeaderboardReducer(builder);
    updateLeaderboardReducer(builder);
  }
});

export const leaderboardReducer = leaderboardSlice.reducer;
