import { useState } from 'react';

import { asyncUpgradeLevel } from '../../../model/boosters/action';
import { UpgradeLevelPayload } from '../../../types/boosters.interfaces';
import { useAppDispatch } from '../useAppDispatch';

interface Props {
  onSuccess?: VoidFunction;
}

export const useUpgradeLevel = ({ onSuccess }: Props) => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const handleUpgradeLevel = (payload: UpgradeLevelPayload | null) => {
    if (!payload) return;

    setIsLoading(true);
    dispatch(asyncUpgradeLevel(payload))
      .unwrap()
      .then(onSuccess)
      .finally(() => {
        setIsLoading(false);
      });
  };

  return { isLoading, handleUpgradeLevel };
};
