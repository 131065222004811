import cn from 'classnames';

import { ReactComponent as CheckedIcon } from 'assets/svg/checked.svg';
import { ReactComponent as PlaceholderIcon } from 'assets/svg/invite_bonus.svg';
import { asyncClaimBonus, Bonus, Button, selectUser, useAppDispatch, useAppSelector } from 'shared';

interface Props {
  data: Bonus;
}

const TOKEN_NAME = localStorage.getItem('TOKEN_NAME');

export const BonusCard = ({ data }: Props) => {
  const user = useAppSelector(selectUser);

  const dispatch = useAppDispatch();

  if (!user) return null;

  const isClaim = user.bonuses.includes(String(data._id));
  const isDone = user.referral_users.length >= data.friends_invited_amount;

  const handleClick = (bonusId: number) => {
    dispatch(asyncClaimBonus({ bonusId }));
  };

  return (
    <div className="rounded-md flex gap-3 items-center">
      <PlaceholderIcon className="w-8" />
      <div className="flex flex-col flex-1">
        <span className="text-lg font-medium leading-4">{data.title}</span>
        <span className="text-[10px] text-[#858994] leading-[14px]">
          {data?.bonus_amount
            ? `+${data.bonus_amount} ${TOKEN_NAME}`
            : `+${data?.bonus_percent}% to claim`}
        </span>
      </div>
      {isClaim ? (
        <div className="flex bg-success-linear rounded-full gap-1 px-2.5">
          <span className="text-[12px]">Claimed</span>
          <CheckedIcon className="w-3 fill-[#FFE100]" />
        </div>
      ) : (
        <Button
          onClick={() => handleClick(data._id)}
          disabled={!isDone}
          className={cn('bg-[#5D5759] px-6 text-[12px]', {
            'bg-[#DF701D]': isClaim
          })}
        >
          Claim
        </Button>
      )}
    </div>
  );
};
