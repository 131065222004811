export interface QueryParams {
  [key: string]: number | string | undefined;
  page?: number;
  limit?: number;
  id?: string;
  type?: LeaderboardType;
}

export enum LeaderboardType {
  ACRIVITY = 'activity',
  POINTS = 'points',
  FRIENDS = 'friends',
  GAMES = 'games'
}
