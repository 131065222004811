import { twMerge } from 'tailwind-merge';

import { ReactComponent as LoadingIcon } from 'assets/svg/loader.svg';
import { LoaderSizesStyles } from 'shared/constants/loader';
import { LoaderSizes } from 'shared/types/loader.interfaces';

interface ILoader {
  className?: string;
  size?: LoaderSizes;
}

export const Loader = ({ className, size = LoaderSizes.BASE }: ILoader) => {
  return (
    <div className="flex justify-center">
      <LoadingIcon
        className={twMerge('animate-spin fill-white', LoaderSizesStyles[size], className)}
      />
    </div>
  );
};
