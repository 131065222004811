import {
  Home,
  Activities,
  Leaderboard,
  Person,
  Boosts,
  Statistic,
  InvitesBonuses,
  TopFriends,
  TopFriendRef
} from 'pages';
import { AppRoutes } from 'shared';
import { PersonRoutes } from 'shared/constants/person-routes';

export const ROUTES = [
  {
    path: AppRoutes.HOME,
    element: Home
  },
  {
    path: AppRoutes.BOOSTS,
    element: Boosts
  },
  {
    path: AppRoutes.ACTIVITY,
    element: Activities
  },
  {
    path: AppRoutes.LEADERS,
    element: Leaderboard
  },
  {
    path: AppRoutes.PERSON,
    element: Person
  }
];

export const PERSON_ROUTES = [
  {
    path: PersonRoutes.STATISTIC,
    element: Statistic
  },
  {
    path: PersonRoutes.INVITES_BONUS,
    element: InvitesBonuses
  },
  {
    path: PersonRoutes.TOP_FRIENDS,
    element: TopFriends
  },
  {
    path: PersonRoutes.TOP_FREN_REFS,
    element: TopFriendRef
  }
];
