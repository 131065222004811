import { twMerge } from 'tailwind-merge';

import { ReactComponent as CoinIcon } from 'assets/svg/coins.svg';

interface Props {
  balance: number;
  className?: string;
}

export const UserBalance = ({ balance, className }: Props) => {
  return (
    <div className={twMerge('flex flex-col items-center', className)}>
      <div className="flex font-bold">
        <span className="text-[40px] leading-7">{balance}</span>
        <CoinIcon className="w-8 ml-1.5 fill-white" />
      </div>
    </div>
  );
};
