import { twMerge } from 'tailwind-merge';

import { Avatar } from 'shared/components/Avatar';

interface Props {
  userImageUrl?: string;
  userName: string;
  className?: string;
}

export const UserCard = ({ userImageUrl, userName, className }: Props) => {
  return (
    <div className={twMerge('flex flex-col items-center', className)}>
      <Avatar src={userImageUrl} className="w-[140px] h-[140px]" />
      <span className="mt-4 text-2xl font-bold">{userName}</span>
    </div>
  );
};
