import { AxiosRequestConfig } from 'axios';

import { httpApiClient } from '../config/http-client.configs';
import { TaskCategory } from '../types/task.interfaces';

export class TasksApi {
  constructor(private url: string) {}

  getTasks(config?: AxiosRequestConfig) {
    return httpApiClient.get<{ taskCategory: TaskCategory[] }>(`${this.url}`, config);
  }
}

export const tasksApi = new TasksApi('/task_category');
