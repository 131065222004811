export enum RewardTypes {
  POINTS = 'points',
  UPGRADE_DAILY_CLAIM = 'upgradeDailyClaim',
  UPGRADE_REWARD_CLAIM = 'upgradeRewardClaim'
}

export interface Reward {
  id: string;
  claim_start: string;
  created_at: string;
  next_claim: string;
  day: number;
  reward_amount: number;
  title: string;
  type: RewardTypes; // TODO - create enum
}

export interface ClaimDailyRewardPayload {
  userId: string | number;
  rewardType: RewardTypes;
}
