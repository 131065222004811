import { ISettindCard, SettingCard } from 'entities/person/components/SettingCard';
import { WithTitleBlock } from 'shared/components/WithTitleBlock';
import { PERSON_SETTINGS } from 'shared/constants/person-setting';

interface PersonSetting {
  id: number;
  category: string;
  items: ISettindCard[];
}

export const SettingsBlock = () => {
  return (
    <div className="flex flex-col space-y-8 mt-8">
      {PERSON_SETTINGS.map(({ id, category, items }: PersonSetting) => (
        <WithTitleBlock key={id} title={category}>
          {items.map((props, index) => (
            <SettingCard key={index} {...props} />
          ))}
        </WithTitleBlock>
      ))}
    </div>
  );
};
