import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { asyncGetLeaderboard, asyncUpdateLeaderboard } from './action';
import { LeaderboardSliceState } from './slice';
import { LeaderboardData, LeaderboardResponse } from '../../types/leaderboard.interfaces';

export function getLeaderboardReducer(builder: ActionReducerMapBuilder<LeaderboardSliceState>) {
  builder.addCase(asyncGetLeaderboard.pending, (state) => {
    state.leaderboard = null;
    state.isLoading = true;
  });

  builder.addCase(
    asyncGetLeaderboard.fulfilled,
    (state, { payload }: PayloadAction<LeaderboardResponse>) => {
      state.timeoutRequest = new Date();

      state.leaderboardType = payload.type;
      state.leaderboard = payload.data;

      state.isLoading = false;
    }
  );

  builder.addCase(asyncGetLeaderboard.rejected, (state) => {
    state.leaderboard = null;
    state.isLoading = false;
  });
}

export function updateLeaderboardReducer(builder: ActionReducerMapBuilder<LeaderboardSliceState>) {
  builder.addCase(asyncUpdateLeaderboard.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(
    asyncUpdateLeaderboard.fulfilled,
    (state, { payload }: PayloadAction<LeaderboardData>) => {
      state.leaderboard = payload;
      state.isLoading = false;
    }
  );

  builder.addCase(asyncUpdateLeaderboard.rejected, (state) => {
    state.isLoading = false;
  });
}
