import { Dispatch } from 'react';

import { Joystick } from 'react-joystick-component';
import { IJoystickUpdateEvent } from 'react-joystick-component/build/lib/Joystick';
import { twMerge } from 'tailwind-merge';

import { ReactComponent as ArrowIcon } from 'assets/svg/arrow.svg';
import { Button } from 'shared';

import { Direction } from '../constants/constants';

interface Props {
  currentDirection: Direction;
  setDirection: Dispatch<React.SetStateAction<Direction>>;
}

const BUTTON_CLASSNAME = 'w-10 h-10';

export const Controller = ({ currentDirection, setDirection }: Props) => {
  const handleClick = (dir: Direction) => {
    setDir(dir);
  };

  const onMove = (stick: IJoystickUpdateEvent) => {
    if (!stick.direction) return;

    setDir(stick.direction as Direction);
  };

  const setDir = (dir: Direction) => {
    switch (dir) {
      case Direction.RIGHT:
        if (currentDirection !== Direction.LEFT && currentDirection !== Direction.RIGHT) {
          setDirection(Direction.RIGHT);
        }
        break;
      case Direction.LEFT:
        if (currentDirection !== Direction.RIGHT && currentDirection !== Direction.LEFT) {
          setDirection(Direction.LEFT);
        }
        break;
      case Direction.DOWN:
        if (currentDirection !== Direction.UP && currentDirection !== Direction.DOWN) {
          setDirection(Direction.DOWN);
        }
        break;
      case Direction.UP:
        if (currentDirection !== Direction.DOWN && currentDirection !== Direction.UP) {
          setDirection(Direction.UP);
        }
        break;
    }
  };

  return (
    <div className="flex justify-between">
      <div className="grid justify-center gap-2">
        <Button
          onClick={() => handleClick(Direction.UP)}
          className={twMerge('justify-self-center self-end', BUTTON_CLASSNAME)}
        >
          <ArrowIcon className="-rotate-90" />
        </Button>
        <div className="flex gap-2">
          <Button onClick={() => handleClick(Direction.LEFT)} className={BUTTON_CLASSNAME}>
            <ArrowIcon className="-rotate-180" />
          </Button>
          <Button onClick={() => handleClick(Direction.DOWN)} className={BUTTON_CLASSNAME}>
            <ArrowIcon className="rotate-90" />
          </Button>
          <Button onClick={() => handleClick(Direction.RIGHT)} className={BUTTON_CLASSNAME}>
            <ArrowIcon />
          </Button>
        </div>
      </div>

      <div className="self-center">
        <Joystick move={onMove} size={120} />
      </div>
    </div>
  );
};
