import { StatisticList } from './StatisticList';

export const StatisticBlock = () => {
  return (
    <div className="flex flex-col h-full overflow-x-hidden overflow-y-auto remove-scrollbar gap-5">
      <h2 className="text-2xl font-bold text-center">PERSONAL STATISTIC</h2>
      <StatisticList />
    </div>
  );
};
