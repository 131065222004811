import cn from 'classnames';
import { useLocation } from 'react-router-dom';

import { AppRoutes, Link, LinkVariants } from 'shared';

import { MENU_ITEMS } from '../constants/menu-items';

export const TabBar = () => {
  const { pathname } = useLocation();

  return (
    <div className="grid grid-cols-5 bg-[#6D6768CC]/80 rounded-2xl py-2 border-1 border-white">
      {MENU_ITEMS.map(({ id, icon: Icon, header, route }, index, array) => (
        <Link
          variant={LinkVariants.TRANSPARENT}
          to={route}
          key={id}
          className={cn('w-full flex flex-col items-center fill-white', {
            'fill-[#FCCB01] text-[#FCCB01]':
              route === AppRoutes.HOME ? route === pathname : pathname.includes(route),
            'border-r border-[#858994]': index + 1 !== array.length
          })}
        >
          <Icon className="w-6 h-6" />
          <span className="text-xs sm:text-base mt-1">{header}</span>
        </Link>
      ))}
    </div>
  );
};
