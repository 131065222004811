import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'app';

const selectState = (x: RootState) => x.bonusReducer;

export const selectBonus = createSelector(selectState, ({ bonus }) => bonus);
export const selectBonuses = createSelector(selectState, ({ bonuses }) => bonuses);

export const selectIsBonusLoading = createSelector(selectState, ({ isLoading }) => isLoading);
