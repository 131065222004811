import { createSlice } from '@reduxjs/toolkit';

import { USER_SLICE_NAME } from './action';
import {
  getUserReducer,
  claimTokensReducer,
  updateBalanceReducer,
  claimBonusReducer,
  isFirstLoadingReducer,
  boostActiveReducer,
  asyncClaimDailyRewardReducer,
  setUserReducer,
  authUserReducer,
  autoBotClaimIncome
} from './reducers';
import { User } from '../../types/users.interfaces';

export interface UserSliceState {
  isFirstLoading: boolean;
  isLoading: boolean;
  user: User | null;
  serverTime: string | null;
  autoBotIncome: number | null;
}

export const initialState: UserSliceState = {
  user: null,
  serverTime: null,
  isLoading: false,
  isFirstLoading: false,
  autoBotIncome: null
};

const userSlice = createSlice({
  name: USER_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getUserReducer(builder);
    claimTokensReducer(builder);
    claimBonusReducer(builder);
    updateBalanceReducer(builder);
    isFirstLoadingReducer(builder);
    boostActiveReducer(builder);
    asyncClaimDailyRewardReducer(builder);
    setUserReducer(builder);
    authUserReducer(builder);
    autoBotClaimIncome(builder);
  }
});

export const userReducer = userSlice.reducer;
