import { ReactNode } from 'react';

import cn from 'classnames';
import { twMerge } from 'tailwind-merge';

interface Props {
  title: string;
  description?: string;
  children?: ReactNode;
  leftSideContent?: ReactNode;
  className?: string;
  styled?: React.CSSProperties;
}

export const InfoBlock = ({
  title,
  description,
  leftSideContent,
  children,
  className,
  styled
}: Props) => {
  return (
    <div
      style={styled}
      className={twMerge(
        cn(
          'flex justify-between items-center px-4 py-1 bg-[#282B30] rounded-xl',
          { 'py-[13px]': !description },
          className
        )
      )}
    >
      <div className="flex items-center">
        {leftSideContent && <div className="mr-3">{leftSideContent}</div>}
        <div>
          <h6 className="font-medium text-[#E4E5EA]">{title}</h6>
          {description && <p className="text-sm text-[#FCFCFE] font-bold">{description}</p>}
        </div>
      </div>
      {children && <div className="flex items-center text-xs">{children}</div>}
    </div>
  );
};
