import { createSlice } from '@reduxjs/toolkit';

import { REWARDS_SLICE_NAME } from './action';
import { getRewardByDayReducer, getRewardsReducer } from './reducers';
import { Reward } from '../../types/rewards.interfaces';

export interface RewardsSliceState {
  rewards: Reward[] | null;
  reward: Reward | null;
}

export const initialState: RewardsSliceState = {
  rewards: null,
  reward: null
};

const rewardsSlice = createSlice({
  name: REWARDS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getRewardByDayReducer(builder);
    getRewardsReducer(builder);
  }
});

export const rewardsReducer = rewardsSlice.reducer;
