import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { asyncGetTasks } from './action';
import { TasksSliceState } from './slice';
import { TaskCategory } from '../../types/task.interfaces';

export function getTasksReducer(builder: ActionReducerMapBuilder<TasksSliceState>) {
  builder.addCase(asyncGetTasks.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncGetTasks.fulfilled, (state, { payload }: PayloadAction<TaskCategory[]>) => {
    state.tasks = payload;
    state.isLoading = false;
  });

  builder.addCase(asyncGetTasks.rejected, (state) => {
    state.isLoading = false;
  });
}
