import React, { memo } from 'react';

import cn from 'classnames';
import { twMerge } from 'tailwind-merge';

import { ReactComponent as BoostIcon } from 'assets/svg/boosts/boost.svg';
import { ReactComponent as CheckedIcon } from 'assets/svg/checked.svg';
import { REWARD_LABELS } from 'shared/constants/rewards';
import { Reward } from 'shared/types/rewards.interfaces';

interface Props {
  reward: Reward;
  currentDay: number;
  secondsLeft: number;
  timeLeft?: string;
}

export const RewardsCard = memo(({ reward, currentDay, timeLeft, secondsLeft }: Props) => {
  const { day, reward_amount: rewardAmount, type } = reward;

  const isCurrentDayCard = currentDay === day;

  const isAvailableNextClaim = secondsLeft <= 0;

  const isAvailableToClaim = isCurrentDayCard && isAvailableNextClaim;

  const isDisabled = (isCurrentDayCard && isAvailableNextClaim) || day <= currentDay;

  const isTimerShown = secondsLeft > 0 && isCurrentDayCard;

  return (
    <div
      className={twMerge(
        cn(
          'relative text-center aspect-square text-white flex flex-col justify-center items-center bg-[#6E6F71] rounded-md cursor-pointer overflow-hidden',
          {
            'bg-gray-600': isDisabled,
            'bg-[#FCCB01] text-black': isAvailableToClaim
          }
        )
      )}
    >
      {isTimerShown && (
        <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center bg-[#FCCB01] text-black">
          {timeLeft}
        </div>
      )}
      <h6 className="font-bold">
        Day{' '}
        <span className={cn('text-[#FCB702]', { 'text-black': isAvailableToClaim })}>{day}</span>
      </h6>
      <div className="w-[30px] h-[30px] flex justify-center items-center">
        {day < currentDay ? <CheckedIcon /> : <BoostIcon />}
      </div>

      <p className={cn('text-[10px] text-[#FCB702]', { 'text-black': isAvailableToClaim })}>
        +{`${rewardAmount} ${REWARD_LABELS[type]}`}
      </p>
    </div>
  );
});

RewardsCard.displayName = 'RewardsCard';
