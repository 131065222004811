import { BoostsList } from 'features/boosts';
import { getTelegramApi } from 'shared';

export const Boosts = () => {
  const tg = getTelegramApi();

  tg.BackButton.isVisible = true;

  return (
    <>
      <h2 className="text-2xl font-bold text-center">BOOST BONUSES</h2>
      <BoostsList />
    </>
  );
};
