import { useState } from 'react';

import { toast } from 'react-toastify';

import {
  asyncAutoBotClaimToken,
  asyncConfirmUnlockAutoBotClaim,
  asyncUnlockAutoBotRequest
} from 'shared/model/user/action';
import { User } from 'shared/types/users.interfaces';

import { TelegramInvoiceStatuses } from '../../../types/telegram.interfaces';
import { getTelegramApi } from '../../utils/telegram';
import { useAppDispatch } from '../useAppDispatch';

interface Props {
  onSuccess?: VoidFunction;
}

export const useAutoBot = ({ onSuccess }: Props) => {
  const dispatch = useAppDispatch();

  const [isLoadingPaymentAutoBot, setIsLoadingPaymentAutoBot] = useState(false);

  const tg = getTelegramApi();

  const handleBoostClaimInvoice = (status: TelegramInvoiceStatuses) => {
    if (status === TelegramInvoiceStatuses.FAILED) {
      toast.error("The payment wasn't successful");
      return;
    }

    if (status === TelegramInvoiceStatuses.CANCELLED) {
      toast.error('The payment was cancelled');
      return;
    }

    dispatch(asyncConfirmUnlockAutoBotClaim())
      .unwrap()
      .then(() => {
        toast.success('The payment was successful');
        onSuccess?.();
      });
  };

  const handleLoadPaymentUrlAutoBot = () => {
    setIsLoadingPaymentAutoBot(true);
    dispatch(asyncUnlockAutoBotRequest())
      .unwrap()
      .then((paymentUrl) => {
        tg?.openInvoice(paymentUrl, (status: TelegramInvoiceStatuses) =>
          handleBoostClaimInvoice(status)
        );
      })
      .finally(() => setIsLoadingPaymentAutoBot(false));
  };

  const handleClaimToken = () =>
    dispatch(asyncAutoBotClaimToken())
      .unwrap()
      .then(() => {
        onSuccess?.();
      });

  return { isLoadingPaymentAutoBot, handleLoadPaymentUrlAutoBot, handleClaimToken };
};
