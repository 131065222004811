import { ReactComponent as DailyIcon } from 'assets/svg/boosts/daily.svg';
import { ReactComponent as FriendsRewardIcon } from 'assets/svg/boosts/friends_rewards.svg';
import { ReactComponent as RewardIcon } from 'assets/svg/boosts/reward.svg';
import { ReactComponent as CoinIcon } from 'assets/svg/coin.svg';
import { ReactComponent as CoinsIcon } from 'assets/svg/coins.svg';
import { selectUser, useAppSelector } from 'shared';
import { InfoBlock } from 'shared/components/InfoBlock';
import { WithTitleBlock } from 'shared/components/WithTitleBlock';

const TOKEN_NAME = localStorage.getItem('TOKEN_NAME');

export const StatisticList = () => {
  const user = useAppSelector(selectUser);

  if (!user) return null;

  const STATISTIC = [
    {
      title: 'Gain & Spend',
      valueClassName: 'font-semibold',
      items: [
        {
          icon: CoinIcon,
          name: 'Total gain',
          value: `${user?.total_gain} ${TOKEN_NAME}`
        },
        {
          icon: CoinsIcon,
          name: 'Total spend',
          value: `${user?.total_spent} ${TOKEN_NAME}`
        }
      ]
    },
    {
      title: 'Upgrades',
      valueClassName: 'font-bold',
      items: [
        {
          icon: DailyIcon,
          name: 'Daily claim',
          value: `${user.daily_claim_lvl} LVL`
        },
        {
          icon: RewardIcon,
          name: 'Reward claim',
          value: `${user.reward_claim_lvl} LVL`
        },
        {
          icon: FriendsRewardIcon,
          name: 'Friends rewards',
          value: `${user.friends_reward_lvl} LVL`
        }
      ]
    }
  ];

  return (
    <div className="space-y-8">
      {STATISTIC.map(({ title, items, valueClassName }) => (
        <WithTitleBlock key={title} title={title} blockClassName={valueClassName}>
          {items.map(({ name, value, icon: Icon }) => (
            <InfoBlock
              key={name}
              title={name}
              description={value}
              leftSideContent={<Icon className="w-[26px] h-[26px]" />}
            />
          ))}
        </WithTitleBlock>
      ))}
    </div>
  );
};
