import UserImage from 'assets/png/user_image.png';

interface Props {
  src?: string;
  alt?: string;
  className: string;
}

export const Avatar = ({ src, alt = 'image', className }: Props) => {
  return (
    <div className={className}>
      <img
        src={src ? `${process.env.REACT_APP_APP_URL}/${src}` : UserImage}
        alt={alt}
        className="w-full h-full rounded-full"
      />
    </div>
  );
};
