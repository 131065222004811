import { ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

import { typographyVariants } from 'shared/constants/typography';

interface Props {
  tag?: keyof typeof typographyVariants;
  className?: string;
  children: ReactNode | string;
}

export const Typography = ({ tag = 'p', className, children }: Props) => {
  const TypographyTag = tag;

  return (
    <TypographyTag className={twMerge('text-white', typographyVariants[tag], className)}>
      {children}
    </TypographyTag>
  );
};
