import { AxiosRequestConfig } from 'axios';

import { httpApiClient } from '../config/http-client.configs';
import { Reward } from '../types/rewards.interfaces';

export class RewardsApi {
  constructor(private url: string) {}

  getRewards(config?: AxiosRequestConfig) {
    return httpApiClient.get<{ rewards: Reward[] }>(`${this.url}`, config);
  }

  getRewardByDay(day: number, config?: AxiosRequestConfig) {
    return httpApiClient.get<Reward>(`${this.url}/${day}`, config);
  }
}

export const rewardsApi = new RewardsApi('/reward');
