import { Button } from 'shared';
import { UpgradeData, UpgradeLevelPayload } from 'shared/types/boosters.interfaces';

interface Props {
  data: UpgradeData | null;
  isLoading?: boolean;
  handleClickButton: (payload: UpgradeLevelPayload | null) => void;
  userId?: number;
}

export const BoostsUpgradeLevelModalContent = ({
  handleClickButton,
  userId,
  data,
  isLoading
}: Props) => {
  const { levels, nextLevel } = data || {};

  const onClick = () => {
    if (!userId || !data) return;

    handleClickButton({ ...data, userId });
  };

  const levelsToMap = nextLevel
    ? levels?.filter(({ level }) => level >= nextLevel?.level)?.slice(0, 10)
    : levels;

  return (
    <div className="flex flex-col justify-between h-full text-xl">
      <div className="text-white mx-auto border border-[#FCB702] px-6 py-2 rounded-md mb-2">
        Current level:{' '}
        <span className="text-[#FCB702]">
          {data?.nextLevel.level ? data?.nextLevel.level - 1 : 0}
        </span>
      </div>
      <ul className="h-4/6 mx-auto overflow-y-scroll mb-4">
        {levelsToMap?.length
          ? levelsToMap?.map(({ level, price }) => (
              <li key={level} className="text-white">
                <span className="text-[#FCB702] inline-block text-right w-5">{level}</span> LVL -{' '}
                <span className="text-[#FCB702]">{price}</span> points
              </li>
            ))
          : null}
      </ul>
      <Button
        isLoading={isLoading}
        onClick={onClick}
        className="mb-6 border-none relative disabled:pointer-events-none items-center transition-all disabled:opacity-50 h-[60px] w-full flex justify-center text-xl text-[#1C0A03] rounded-2xl bg-[#FCCB01] font-bold overflow-hidden"
      >
        UPGRADE
      </Button>
    </div>
  );
};
