import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { upgradesApi, userApi } from '../../apis/boosters-api';
import { showServerError } from '../../lib/utils/modules';
import { Upgrade, UpgradeLevelPayload, UpgradeTypes } from '../../types/boosters.interfaces';
import { setUser } from '../user/action';

export const BOOSTS_SLICE_NAME = 'boosters';

export const asyncGetUpgrades = createAsyncThunk(
  `${BOOSTS_SLICE_NAME}/getUpgrades`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await upgradesApi.getUpgrades();

      return response?.data?.upgrades?.reduce(
        (acc, curr) => ({ ...acc, [curr.type]: curr }),
        {}
      ) as Record<UpgradeTypes, Upgrade>;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  }
);

export const asyncUpgradeLevel = createAsyncThunk(
  `${BOOSTS_SLICE_NAME}/upgradeLevel`,
  async ({ label, ...payload }: UpgradeLevelPayload, { dispatch, rejectWithValue }) => {
    try {
      const response = await userApi.upgradesUpgradeLevel(payload);

      dispatch(setUser(response?.data?.user));

      toast.success(`${label} was successfully upgraded`);

      return response?.data?.user;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  }
);
