import { Route, Routes } from 'react-router-dom';

import { PagesCommonLayout } from './PagesCommonLayout';
import { PERSON_ROUTES, ROUTES } from '../constants/routes';

export const App = () => {
  return (
    <Routes>
      <Route element={<PagesCommonLayout />}>
        {ROUTES.map(({ path, element: Element }) => (
          <Route key={path} path={path} element={<Element />} />
        ))}
        <Route path="person">
          {PERSON_ROUTES.map(({ path, element: Element }) => (
            <Route key={path} path={path} element={<Element />} />
          ))}
        </Route>
      </Route>
    </Routes>
  );
};
