import { useEffect } from 'react';

import eruda from 'eruda';
import { Outlet, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { AutoBot } from 'features/auto-bot';
import { TabBar } from 'features/tab-bar';
import {
  asyncGetUser,
  authUser,
  getTelegramApi,
  selectUser,
  setIsFirstLoading,
  useAppDispatch,
  useAppSelector
} from 'shared';
import { Loader } from 'shared/components/Loader';
import { LoaderSizes } from 'shared/types/loader.interfaces';

const TOKEN = localStorage.getItem('token');

export const PagesCommonLayout = () => {
  const user = useAppSelector(selectUser);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const tg = getTelegramApi();

  useEffect(() => {
    dispatch(setIsFirstLoading(false));
    tg.ready();
    tg.expand();
    eruda.init();
    tg.setHeaderColor('#000');
    tg.disableVerticalSwipes();
    tg.BackButton.onClick(() => navigate(-1));

    dispatch(authUser({ initData: tg?.initData }));

    if (TOKEN) {
      dispatch(
        asyncGetUser({ id: 111117, username: 'userName', ...tg?.initDataUnsafe?.user })
      ).finally(() => {
        setTimeout(() => dispatch(setIsFirstLoading(true)), 100);
      });
    }
  }, [tg, dispatch, navigate]);

  if (!user || !TOKEN) {
    return (
      <div className="h-dvh flex flex-col justify-center w-screen text-white font-RedHatDisplay overflow-hidden bg-black">
        <Loader size={LoaderSizes.XL} />
      </div>
    );
  }

  return (
    <div className="h-dvh flex flex-col w-screen text-white font-RedHatDisplay overflow-hidden bg-black">
      <main className="flex flex-col h-full overflow-x-hidden overflow-y-auto remove-scrollbar pt-6 px-7">
        <Outlet />
      </main>
      <footer className="px-7 pt-4 pb-6">
        <TabBar />
      </footer>
      <ToastContainer stacked autoClose={3000} limit={3} />
      <AutoBot user={user} />
    </div>
  );
};
