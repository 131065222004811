import { createAsyncThunk } from '@reduxjs/toolkit';

import { bonusesApi } from '../../apis/bonuses-api';
import { showServerError } from '../../lib/utils/modules';

export const BONUSES_SLICE_NAME = 'tasks';

export const asyncGetBonuses = createAsyncThunk(
  `${BONUSES_SLICE_NAME}/getBonuses`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await bonusesApi.getBonuses();
      return response.data.bonusCategory;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  }
);
