import { useState } from 'react';

import { GameBoard } from 'features/game-board';
import { Button, ButtonVariants, getTelegramApi } from 'shared';
import { InfoBlock } from 'shared/components/InfoBlock';

import { ReactComponent as SnakeIcon } from '../assets/svg/activity/snake.svg';

export const Activities = () => {
  const [isPlaing, seyIsPlaying] = useState(false);

  const tg = getTelegramApi();

  tg.BackButton.isVisible = true;

  const handleClick = () => {
    seyIsPlaying(true);
  };

  return (
    <div className="h-full">
      <h2 className="text-2xl font-bold text-center">ACTIVITIES</h2>
      {isPlaing ? (
        <GameBoard />
      ) : (
        <div className="space-y-8 mt-8">
          <InfoBlock
            title={'Snake game'}
            leftSideContent={<SnakeIcon className="w-[26px] h-[26px] fill-white" />}
          >
            <Button
              onClick={handleClick}
              variant={ButtonVariants.OUTLINED}
              className="w-[76px] leading-5"
            >
              Play
            </Button>
          </InfoBlock>
        </div>
      )}
    </div>
  );
};
