import { MouseEvent } from 'react';

import { Link } from 'react-router-dom';

import { ReactComponent as ArrowIcon } from 'assets/svg/arrow.svg';
import { Button, getTelegramApi, selectUser, SVGComponent, useAppSelector } from 'shared';
import { InfoBlock } from 'shared/components/InfoBlock';
import { PersonRoutes } from 'shared/constants/person-routes';

export interface ISettindCard {
  icon: SVGComponent;
  title: string;
  path: PersonRoutes | string;
  action?: string;
}

export const SettingCard = ({ icon: Icon, title, path, action }: ISettindCard) => {
  const user = useAppSelector(selectUser);

  const tg = getTelegramApi();

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    tg.openTelegramLink(`https://t.me/share/url?url=${user?.referralLink}`);
  };

  return (
    <Link to={path}>
      <InfoBlock title={title} leftSideContent={<Icon className="w-[26px] h-[26px]" />}>
        {action === 'button' ? (
          <Button onClick={handleClick} className="bg-scorpion px-4">
            Invite
          </Button>
        ) : (
          <ArrowIcon className="w-5 h-5" />
        )}
      </InfoBlock>
    </Link>
  );
};
