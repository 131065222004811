import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { asyncGetUpgrades } from './action';
import { BoostersSliceState } from './slice';
import { Upgrade, UpgradeTypes } from '../../types/boosters.interfaces';

export function getUpgradesReducer(builder: ActionReducerMapBuilder<BoostersSliceState>) {
  builder.addCase(
    asyncGetUpgrades.fulfilled,
    (state, { payload }: PayloadAction<Record<UpgradeTypes, Upgrade>>) => {
      state.upgrades = payload;
    }
  );
}
