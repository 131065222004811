import { useState } from 'react';

import { UpgradeData } from '../../../types/boosters.interfaces';
import { useBoolean } from '../useBoolean';

export const useUpgradeData = (): [
  {
    isUpgradeModalOpen: boolean;
    handleOpenUpgradeModal: (data: UpgradeData) => void;
    handleCloseUpgradeModal: VoidFunction;
  },
  UpgradeData | null
] => {
  const [isUpgradeModalOpen, handleOpenUpgradeModal, handleCloseUpgradeModal] = useBoolean();
  const [upgradeData, setUpgradeData] = useState<UpgradeData | null>(null);

  const onOpenUpgradeModal = (data: UpgradeData) => {
    setUpgradeData(data);
    handleOpenUpgradeModal();
  };

  const onCloseUpgradeModal = () => {
    setUpgradeData(null);
    handleCloseUpgradeModal();
  };

  return [
    {
      isUpgradeModalOpen,
      handleOpenUpgradeModal: onOpenUpgradeModal,
      handleCloseUpgradeModal: onCloseUpgradeModal
    },
    upgradeData
  ];
};
