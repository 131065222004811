import { createAsyncThunk } from '@reduxjs/toolkit';

import { tasksApi } from '../../apis/tasks-api';
import { showServerError } from '../../lib/utils/modules';

export const TASKS_SLICE_NAME = 'tasks';

export const asyncGetTasks = createAsyncThunk(
  `${TASKS_SLICE_NAME}/getTasks`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await tasksApi.getTasks();
      return response.data.taskCategory;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  }
);
