import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { getRewardByDay, getRewards } from './action';
import { RewardsSliceState } from './slice';
import { Reward } from '../../types/rewards.interfaces';

export function getRewardByDayReducer(builder: ActionReducerMapBuilder<RewardsSliceState>) {
  builder.addCase(getRewardByDay.fulfilled, (state, { payload }: PayloadAction<Reward>) => {
    state.reward = payload;
  });
}

export function getRewardsReducer(builder: ActionReducerMapBuilder<RewardsSliceState>) {
  builder.addCase(getRewards.fulfilled, (state, { payload }: PayloadAction<Reward[]>) => {
    state.rewards = payload;
  });
}
