import { AxiosRequestConfig } from 'axios';

import { ClaimBonusPayload, User } from 'shared/types/users.interfaces';

import { httpApiClient } from '../config/http-client.configs';
import { BonusCategory } from '../types/bonus.interfaces';

export class BonusesApi {
  constructor(private url: string) {}

  getBonuses(config?: AxiosRequestConfig) {
    return httpApiClient.get<{ bonusCategory: BonusCategory[] }>(`${this.url}-category`, config);
  }

  claimBonus({ bonusId }: ClaimBonusPayload, config?: AxiosRequestConfig) {
    return httpApiClient.patch<{ user: User }>(`${this.url}/claim-reward/${bonusId}/`, config);
  }
}

export const bonusesApi = new BonusesApi('/bonus');
