import { ReactComponent as ActivityIcon } from 'assets/svg/tab-bar/activity.svg';
import { ReactComponent as BoostIcon } from 'assets/svg/tab-bar/boosts.svg';
import { ReactComponent as HomeIcon } from 'assets/svg/tab-bar/home.svg';
import { ReactComponent as LeadersIcon } from 'assets/svg/tab-bar/leaders.svg';
import { ReactComponent as PersonIcon } from 'assets/svg/tab-bar/person.svg';
import { AppRoutes } from 'shared';

import { MenuItems } from '../types/menu-items.interfaces';

export const MENU_ITEMS: MenuItems[] = [
  {
    id: 0,
    icon: HomeIcon,
    header: 'Home',
    route: AppRoutes.HOME
  },
  {
    id: 1,
    icon: BoostIcon,
    header: 'Boosts',
    route: AppRoutes.BOOSTS
  },
  {
    id: 2,
    icon: ActivityIcon,
    header: 'Activity',
    route: AppRoutes.ACTIVITY
  },
  {
    id: 3,
    icon: LeadersIcon,
    header: 'Leaders',
    route: AppRoutes.LEADERS
  },
  {
    id: 4,
    icon: PersonIcon,
    header: 'Person',
    route: AppRoutes.PERSON
  }
];
