import cn from 'classnames';

import { BonusCard } from 'entities/bonuses';
import { Bonus } from 'shared';

interface Props {
  title?: string;
  isTitleUnstyled?: boolean;
  data: Bonus[];
}

export const BonusesList = ({ title, isTitleUnstyled, data }: Props) => {
  return (
    <div className="flex flex-col gap-2 border-t border-scorpion">
      {title && (
        <span
          className={cn('text-22 font-bold', {
            'p-4 text-lg border rounded-md text-center bg-blue-100': !isTitleUnstyled
          })}
        >
          {title}
        </span>
      )}
      {data.map((bonus, index) => (
        <BonusCard key={bonus.title + index} data={bonus} />
      ))}
    </div>
  );
};
