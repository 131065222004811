import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { asyncGetBonuses } from './action';
import { BonusesSliceState } from './slice';
import { BonusCategory } from '../../types/bonus.interfaces';

export function getBonusesReducer(builder: ActionReducerMapBuilder<BonusesSliceState>) {
  builder.addCase(asyncGetBonuses.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(
    asyncGetBonuses.fulfilled,
    (state, { payload }: PayloadAction<BonusCategory[]>) => {
      state.bonuses = payload;
      state.isLoading = false;
    }
  );

  builder.addCase(asyncGetBonuses.rejected, (state) => {
    state.isLoading = false;
  });
}
