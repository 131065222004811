import { useState } from 'react';

import { toast } from 'react-toastify';

import {
  asyncConfirmUnlockBoostClaim,
  asyncUnlockBoostClaimRequest
} from '../../../model/user/action';
import { TelegramInvoiceStatuses } from '../../../types/telegram.interfaces';
import { getTelegramApi } from '../../utils/telegram';
import { useAppDispatch } from '../useAppDispatch';

interface Props {
  onSuccess?: VoidFunction;
}

export const useBoostClaim = ({ onSuccess }: Props) => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const tg = getTelegramApi();

  const handleBoostClaimInvoice = (status: TelegramInvoiceStatuses) => {
    if (status === TelegramInvoiceStatuses.FAILED) {
      toast.error("The payment wasn't successful");
      return;
    }

    if (status === TelegramInvoiceStatuses.CANCELLED) {
      toast.error('The payment was cancelled');
      return;
    }

    dispatch(asyncConfirmUnlockBoostClaim())
      .unwrap()
      .then(() => {
        toast.success('The payment was successful');
        onSuccess?.();
      });
  };

  const handleLoadPaymentUrl = () => {
    setIsLoading(true);
    dispatch(asyncUnlockBoostClaimRequest())
      .unwrap()
      .then((paymentUrl) => {
        tg?.openInvoice(paymentUrl, (status: TelegramInvoiceStatuses) =>
          handleBoostClaimInvoice(status)
        );
      })
      .finally(() => setIsLoading(false));
  };

  return { isLoading, handleLoadPaymentUrl };
};
