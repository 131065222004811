import { LeaderboardCard } from 'entities/leaderboard';
import { selectUser, useAppSelector } from 'shared';
import { LeaderboardType } from 'shared/types/query.interfaces';

export const TopFriends = () => {
  const user = useAppSelector(selectUser);

  if (!user?.referral_users) return null;

  return (
    <main className="flex flex-col gap-3.5">
      <h2 className="text-2xl text-center font-bold">YOUR FRIENDS</h2>
      <div className="flex flex-col gap-3.5">
        {user.referral_users.map(({ reward, user }, index) => (
          <LeaderboardCard
            key={reward + index}
            position={index + 1}
            data={user}
            type={LeaderboardType.POINTS}
          />
        ))}
      </div>
    </main>
  );
};
