import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { bonusesApi } from 'shared/apis/bonuses-api';
import { boostersApi } from 'shared/apis/boosters-api';
import { authApi } from 'shared/apis/login-api';
import { LoginPayload } from 'shared/types/auth.interfaces';
import { BoostActivePayload } from 'shared/types/boosters.interfaces';

import { usersApi } from '../../apis/users-api';
import { showServerError } from '../../lib/utils/modules';
import { ClaimDailyRewardPayload } from '../../types/rewards.interfaces';
import {
  BalancePayload,
  ClaimBonusPayload,
  ClaimPayload,
  User
} from '../../types/users.interfaces';

export const USER_SLICE_NAME = 'user';

export const authUser = createAsyncThunk(
  `${USER_SLICE_NAME}/authUser`,
  async (payload: LoginPayload, { rejectWithValue }) => {
    try {
      const mockInitData =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTExMTE3LCJmaXJzdF9uYW1lIjoidXNlck5hbWUiLCJkYXRlIjoiMjAyNC0wOS0wOVQwOTozOTowOC43NDBaIiwiaWF0IjoxNzI1ODc0NzQ4fQ._TsNaNJFMaiEB8Yl08rAoJsC5diow6_c2fcySyDA8Pc';

      const mockResponse = { data: { accessToken: mockInitData } };

      const response = process.env.REACT_APP_IS_LOCALHOST
        ? mockResponse
        : await authApi.loginUser(payload);

      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  }
);

export const asyncGetUser = createAsyncThunk(
  `${USER_SLICE_NAME}/getUser`,
  async (payload: User, { rejectWithValue }) => {
    try {
      const response = await usersApi.getUser(payload);
      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  }
);

export const asyncUpdateBalance = createAsyncThunk(
  `${USER_SLICE_NAME}/updateBalance`,
  async (payload: BalancePayload, { rejectWithValue }) => {
    try {
      const response = await usersApi.updateBalance(payload);
      return response.data.user;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  }
);

export const asyncClaimTokens = createAsyncThunk(
  `${USER_SLICE_NAME}/claimTokens`,
  async (payload: ClaimPayload, { rejectWithValue }) => {
    try {
      const response = await usersApi.claimTokens(payload);
      return response.data.user;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  }
);

export const asyncClaimDailyReward = createAsyncThunk(
  `${USER_SLICE_NAME}/claimDailyReward`,
  async (payload: ClaimDailyRewardPayload, { rejectWithValue }) => {
    try {
      const response = await usersApi.claimDailyReward(payload);
      return response.data.user;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  }
);

export const asyncClaimBonus = createAsyncThunk(
  `${USER_SLICE_NAME}/claimBonus`,
  async ({ bonusId }: ClaimBonusPayload, { rejectWithValue }) => {
    try {
      const response = await bonusesApi.claimBonus({ bonusId });

      return response.data.user;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  }
);

export const setIsFirstLoading = createAction(`${USER_SLICE_NAME}/isFirstLoading`, (value) => {
  return { payload: value };
});

export const asyncBoostActive = createAsyncThunk(
  `${USER_SLICE_NAME}/boostActive`,
  async (payload: BoostActivePayload, { rejectWithValue }) => {
    try {
      const response = await boostersApi.boostActive(payload);

      return response.data.user;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  }
);

export const asyncUnlockBoostClaimRequest = createAsyncThunk(
  `${USER_SLICE_NAME}/unlockBoostClaimRequest`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await boostersApi.unlockBoostClaim();

      return response?.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  }
);

export const asyncConfirmUnlockBoostClaim = createAsyncThunk(
  `${USER_SLICE_NAME}/confirmUnlockBoostClaim`,
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await boostersApi.confirmUnlockBoostClaim();

      dispatch(setUser(response.data.user));

      return response?.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  }
);

export const asyncUnlockAutoBotRequest = createAsyncThunk(
  `${USER_SLICE_NAME}/confirmUnlockAutoBot`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await boostersApi.unlockAutoBot();

      return response?.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  }
);

export const asyncConfirmUnlockAutoBotClaim = createAsyncThunk(
  `${USER_SLICE_NAME}/confirmUnlockAutoBot`,
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await boostersApi.confirmUnlockAutoBot();

      dispatch(setUser(response.data.user));

      return response?.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  }
);

export const asyncAutoBotClaimToken = createAsyncThunk(
  `${USER_SLICE_NAME}/autoBotClaimTokens`,
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await usersApi.autoBotClaimTokens();

      dispatch(setUser(response.data.user));

      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  }
);

export const setUser = createAction<User>('SET_USER');
