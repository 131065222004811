import { ReactComponent as FriendsIcon } from 'assets/svg/person/friends.svg';
import { ReactComponent as FriendsRefIcon } from 'assets/svg/person/friends_ref.svg';
import { ReactComponent as PersonalIcon } from 'assets/svg/person/personal.svg';
import { ReactComponent as SharedIcon } from 'assets/svg/person/shared.svg';
import { ReactComponent as SocialIcon } from 'assets/svg/person/social.svg';

import { PersonRoutes } from './person-routes';

export const PERSON_SETTINGS = [
  {
    id: 1,
    category: 'Statistic',
    items: [
      {
        icon: PersonalIcon,
        title: 'Personal',
        path: PersonRoutes.STATISTIC
      }
    ]
  },
  {
    id: 2,
    category: 'Friends',
    items: [
      {
        icon: SharedIcon,
        title: 'Share for friends',
        action: 'button',
        path: PersonRoutes.INVITES_BONUS
      },
      {
        icon: FriendsIcon,
        title: 'Top friends',
        path: PersonRoutes.TOP_FRIENDS
      },
      {
        icon: FriendsRefIcon,
        title: 'Top fren refs',
        path: PersonRoutes.TOP_FREN_REFS
      }
    ]
  },
  {
    id: 3,
    category: 'Social',
    items: [
      {
        icon: SocialIcon,
        title: 'Check our group',
        path: 'https://t.me'
      },
      {
        icon: SocialIcon,
        title: 'Chat with community',
        path: 'https://t.me'
      }
    ]
  }
];
