import { ReactComponent as PositionIcon } from 'assets/svg/leaderboard/position.svg';
import { ReactComponent as PositionSecondIcon } from 'assets/svg/leaderboard/position_second.svg';
import { ReactComponent as PositionThirdIcon } from 'assets/svg/leaderboard/position_third.svg';
import { ReactComponent as PositionFirstIcon } from 'assets/svg/leaderboard/positon_first.svg';
import { User } from 'shared';
import { Avatar } from 'shared/components/Avatar';
import { InfoBlock } from 'shared/components/InfoBlock';
import { Loader } from 'shared/components/Loader';
import { LeaderboardType } from 'shared/types/query.interfaces';

interface Props {
  position: number;
  type: LeaderboardType;
  data: User;
  leaderCardClass?: string;
}

const TOKEN_NAME = localStorage.getItem('TOKEN_NAME');

export const LeaderboardCard = ({ position, data, type }: Props) => {
  return (
    <InfoBlock
      title={data?.username ?? data.first_name}
      description={
        type === LeaderboardType.POINTS
          ? `${data?.claimed_balance} ${TOKEN_NAME}`
          : `${data?.referral_users.length} ${
              data?.referral_users.length === 1 ? 'friend' : 'friends'
            }`
      }
      leftSideContent={
        <Avatar src={data.avatar} alt="avatar" className="w-9 h-9 rounded-full bg-white" />
      }
    >
      <div className="flex justify-center items-center gap-[2px] font-semibold">
        {position === 0 && <Loader />}
        {position === 1 && <PositionFirstIcon className="w-4" />}
        {position === 2 && <PositionSecondIcon className="w-4" />}
        {position === 3 && <PositionThirdIcon className="w-4" />}
        {position > 3 && <PositionIcon className="w-[18px]" />}

        {position > 0 && <span className="w-5 text-xl text-center text-white">{position}</span>}
      </div>
    </InfoBlock>
  );
};
