import { useEffect } from 'react';

import { differenceInHours } from 'date-fns';

import RobotGif from 'assets/gif/auto-bot.gif';
import {
  Modal,
  selectIncomeAutoBot,
  selectServerTime,
  useAppSelector,
  useBoolean,
  User
} from 'shared';
import { Typography } from 'shared/components/Typography';
import { useAutoBot } from 'shared/lib/hooks/boosts/useAutoBot';

interface Props {
  user: User;
}

export const AutoBot = ({ user }: Props) => {
  const autobotIncomeClaim = useAppSelector(selectIncomeAutoBot);
  const serverTime = useAppSelector(selectServerTime);

  const [isOpen, handleOpen, handleClose] = useBoolean();
  const { handleClaimToken } = useAutoBot({ onSuccess: handleOpen });

  useEffect(() => {
    if (!user.last_claim || !serverTime) return;

    const isFullClaim = differenceInHours(serverTime, user.last_claim) >= 4;

    if (user.bot_available && isFullClaim) {
      handleClaimToken();
    }
  }, [user.bot_available]);

  return (
    <Modal isOpened={isOpen} handleClose={handleClose} header="Auto bot" height={40}>
      <div className="flex flex-col justify-between h-full">
        <Typography>
          Bzz... Bzzz... In your absence I farmed{' '}
          <Typography tag="span" className="text-[#FCB702]">
            {autobotIncomeClaim}
          </Typography>{' '}
          points.
        </Typography>
        <img className="rounded-md justify-self-center h-3/4 mx-auto" src={RobotGif} />
      </div>
    </Modal>
  );
};
