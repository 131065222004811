import { MouseEvent, useRef } from 'react';

import { twMerge } from 'tailwind-merge';

import { ReactComponent as TimerIcon } from 'assets/svg/timer.svg';
import {
  asyncClaimTokens,
  Button,
  selectUser,
  useAppDispatch,
  useAppSelector,
  useAvailableClaim
} from 'shared';
import { useTimer } from 'shared/lib/hooks/useTimer';

interface Props {
  userId: number;
  lastClaim?: string;
  className?: string;
}

export const ButtonClaim = ({ userId, lastClaim, className }: Props) => {
  const [timeLeftClaim, isCalcClaimTime] = useTimer(lastClaim, 4, 0, 0);
  const [doubleBoost, isCalcBoostTime] = useTimer(lastClaim, 4, 5, 0);

  const buttonContainerRef = useRef<HTMLDivElement | null>(null);

  const user = useAppSelector(selectUser);

  const dispatch = useAppDispatch();

  const availableClaim = useAvailableClaim();

  const isFullClaim = availableClaim >= 1250;

  // TODO - Taras - uncomment
  // const isBoostClaim = isFullClaim && isCalcBoostTime;
  // const isDoubleClaim = !isCalcClaimTime && isCalcBoostTime;

  const sendClaimedTokens = (event: MouseEvent<HTMLButtonElement>) => {
    let boostClaim = 1;

    if (user?.double_claim) boostClaim = 2;

    if (isCalcBoostTime && !isCalcClaimTime) boostClaim = boostClaim * 2;

    const CLAIM_TYPE = boostClaim === 2 ? 'double' : 'quadro';

    const UPDATE_CLAIM = availableClaim * boostClaim;

    if (!userId) return;

    dispatch(
      asyncClaimTokens({
        income: UPDATE_CLAIM,
        ...(boostClaim > 1 ? { type: CLAIM_TYPE } : undefined)
      })
    )
      .unwrap()
      .then(() => {
        const eventTarget = event.target as HTMLButtonElement;

        const rect = eventTarget.getBoundingClientRect();
        const animationNode = document.createElement('div');
        animationNode.classList.add('move_up');
        animationNode.textContent = `+${UPDATE_CLAIM}`;
        animationNode.style.left = `${event.clientX - rect?.left}px`;
        animationNode.style.top = `${event.clientY - rect?.top - 10}px`;

        buttonContainerRef?.current?.appendChild(animationNode);

        const removeAnimationNodeTimeout = setTimeout(() => {
          animationNode.remove();
        }, 2000);

        clearTimeout(removeAnimationNodeTimeout);
      });
  };

  return (
    <div
      className={twMerge('relative flex flex-col w-full justify-center items-center', className)}
    >
      <div className="w-full relative" ref={buttonContainerRef}>
        <Button
          disabled={!availableClaim}
          onClick={sendClaimedTokens}
          className="h-[60px] w-full flex justify-between text-xl text-[#1C0A03] rounded-2xl bg-[#FCCB01] font-bold disabled:opacity-100 overflow-hidden"
        >
          <span className="flex flex-col items-center text-xs bg-[#FCB702] py-3 px-2 min-w-[66px]">
            <TimerIcon className="w-4 h-4 mb-1" />
            {isCalcClaimTime ? `${timeLeftClaim}` : `${doubleBoost}`}
          </span>
          <span className="w-full flex items-center justify-center py-3 px-2 text-[#AB7F00]">
            <strong className="text-black mr-2">{availableClaim} </strong>
            {isFullClaim ? 'FULL CLAIM!' : 'CLAIM'}
          </span>
        </Button>
      </div>
    </div>
  );
};
