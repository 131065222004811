import { useState } from 'react';

import useInfiniteScroll from 'react-infinite-scroll-hook';

import { LeaderboardCard } from 'entities/leaderboard';
import {
  asyncUpdateLeaderboard,
  selectIsLeaderboardLoading,
  selectLeaderboard,
  selectUser,
  useAppDispatch,
  useAppSelector
} from 'shared';
import { Loader } from 'shared/components/Loader';
import { Typography } from 'shared/components/Typography';
import { WithTitleBlock } from 'shared/components/WithTitleBlock';
import { LeaderboardType } from 'shared/types/query.interfaces';
import { SkeletonLeaderCard } from 'widgets/skeleton/components/SkeletonLeaderCard';

import { LeaderboardList } from './LeaderboardList';

interface Props {
  typeLeaderboard: LeaderboardType;
}

export const LeaderboardBlockContent = ({ typeLeaderboard }: Props) => {
  const dispatch = useAppDispatch();

  const isLoadingData = useAppSelector(selectIsLeaderboardLoading);
  const leaderboardData = useAppSelector(selectLeaderboard);
  const user = useAppSelector(selectUser);

  const LEADERBOARD_LENGTH = leaderboardData?.leaderboard.users.length;

  const [hasMore, setHasMore] = useState<boolean>(
    LEADERBOARD_LENGTH ? LEADERBOARD_LENGTH >= leaderboardData?.count : true
  );

  const loadMore = () => {
    if (leaderboardData) {
      const LEADERBOARD_LENGTH = leaderboardData?.leaderboard.users.length;

      if (LEADERBOARD_LENGTH >= leaderboardData?.count) {
        setHasMore(false);
      }

      dispatch(
        asyncUpdateLeaderboard({
          limit: LEADERBOARD_LENGTH + 10,
          page: 0,
          user_id: user?.id,
          type: typeLeaderboard
        })
      );
    }
  };

  const [sentryRef] = useInfiniteScroll({
    loading: isLoadingData,
    hasNextPage: hasMore,
    onLoadMore: loadMore
  });

  return (
    <div className="space-y-8">
      <WithTitleBlock title="Your score">
        {user && leaderboardData ? (
          <LeaderboardCard
            key={user?.id}
            data={user}
            position={leaderboardData.position}
            leaderCardClass="bg-[#6D6768] rounded-[30px] py-1 px-1"
            type={typeLeaderboard}
          />
        ) : (
          <SkeletonLeaderCard />
        )}
      </WithTitleBlock>
      <WithTitleBlock title="General">
        {leaderboardData ? (
          <LeaderboardList data={leaderboardData.leaderboard.users} type={typeLeaderboard} />
        ) : (
          <div className="space-y-1.5">
            <SkeletonLeaderCard />
            <Loader />
          </div>
        )}

        {!isLoadingData && hasMore && leaderboardData && (
          <div ref={sentryRef}>
            <Loader />
          </div>
        )}
        {!hasMore && (
          <Typography tag="h3" className="text-[#9b9b9b] text-center">
            ...
          </Typography>
        )}
      </WithTitleBlock>
    </div>
  );
};
