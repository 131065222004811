import { createAsyncThunk } from '@reduxjs/toolkit';

import { rewardsApi } from '../../apis/rewards-api';
import { showServerError } from '../../lib/utils/modules';
import { TIERS_SLICE_NAME } from '../tier/action';

export const REWARDS_SLICE_NAME = 'rewards';

export const getRewards = createAsyncThunk(
  `${TIERS_SLICE_NAME}/getRewards`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await rewardsApi.getRewards();
      return response.data?.rewards;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  }
);

export const getRewardByDay = createAsyncThunk(
  `${TIERS_SLICE_NAME}/getRewardByDay`,
  async (day: number, { rejectWithValue }) => {
    try {
      const response = await rewardsApi.getRewardByDay(day);
      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  }
);
