import React, { useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import {
  asyncClaimDailyReward,
  Button,
  getRewards,
  selectUser,
  useAppDispatch,
  useAppSelector
} from 'shared';
import { useTimer } from 'shared/lib/hooks/useTimer';
import { selectRewards } from 'shared/model/rewards/selectors';

import { RewardsCard } from './RewardsCard';

const RewardsBlock = () => {
  const dispatch = useAppDispatch();

  const rewards = useAppSelector(selectRewards);

  const {
    id: userId,
    daily_last_claim: dailyLastClaim,
    daily_counter: dailyCounter
  } = useAppSelector(selectUser) || {};

  const [timeLeftToAvailableClaim, _, secondsLeftToAvailableClaim] = useTimer(
    dailyLastClaim,
    24,
    0,
    0
  );

  const [isAdditionalDisabled, setIsAdditionalDisabled] = useState(false);

  const currentDay = (dailyCounter || 0) + 1;

  const isAvailableToClaim = secondsLeftToAvailableClaim <= 0;

  const handleClaimDailyReward = () => {
    if (!userId) return;

    const rewardType = rewards?.find(({ day }) => day === currentDay)?.type;

    if (!rewardType) return;

    dispatch(asyncClaimDailyReward({ userId, rewardType }))
      .unwrap()
      .then(() => {
        toast.success('Daily reward successfully claimed');
        setIsAdditionalDisabled(true);
        const timer = setTimeout(() => setIsAdditionalDisabled(false), 1000);

        return () => clearTimeout(timer);
      });
  };

  useEffect(() => {
    dispatch(getRewards());
  }, [dispatch]);

  return (
    <>
      <div className="flex flex-col justify-between h-full">
        <div className="grid grid-cols-4 grid-cols-min auto-rows-min gap-1 h-4/5 overflow-y-auto">
          {rewards?.length
            ? rewards.map((reward) => {
                const isCurrentDay = reward.day === currentDay;

                return (
                  <RewardsCard
                    secondsLeft={isCurrentDay ? secondsLeftToAvailableClaim : 0}
                    timeLeft={isCurrentDay ? timeLeftToAvailableClaim : undefined}
                    key={reward.id}
                    reward={reward}
                    currentDay={currentDay}
                  />
                );
              })
            : null}
        </div>
        <div className="h-1/5 flex items-end">
          <Button
            disabled={!isAvailableToClaim || isAdditionalDisabled}
            onClick={handleClaimDailyReward}
            className="mt-6 h-[60px] w-full flex justify-center text-xl text-[#1C0A03] rounded-2xl bg-[#FCCB01] font-bold overflow-hidden"
          >
            {isAvailableToClaim ? 'ClAIM REWARD' : timeLeftToAvailableClaim}
          </Button>
        </div>
      </div>
    </>
  );
};

export default RewardsBlock;
