import cn from 'classnames';

import { LeaderboardCard } from 'entities/leaderboard';
import { User } from 'shared';
import { LeaderboardType } from 'shared/types/query.interfaces';

interface Props {
  title?: string;
  type: LeaderboardType;
  isTitleUnstyled?: boolean;
  data: User[];
}

export const LeaderboardList = ({
  title,
  isTitleUnstyled,
  data,
  type = LeaderboardType.POINTS
}: Props) => {
  return (
    <div className="flex flex-col">
      {title && (
        <span
          className={cn({
            'p-4 text-lg rounded-md text-center bg-blue-100': !isTitleUnstyled
          })}
        >
          {title}
        </span>
      )}

      <div className=" space-y-1.5">
        {data.map((leaderboardItem, index) => (
          <LeaderboardCard
            key={leaderboardItem.id}
            position={index + 1}
            data={leaderboardItem}
            type={type}
          />
        ))}
      </div>
    </div>
  );
};
