import { AxiosRequestConfig } from 'axios';

import { BoostActivePayload, Upgrade, UpgradeLevelPayload } from 'shared/types/boosters.interfaces';
import { User } from 'shared/types/users.interfaces';

import { httpApiClient } from '../config/http-client.configs';

export class BoostersApi {
  constructor(private url: string) {}

  boostActive({ boostType }: BoostActivePayload, config?: AxiosRequestConfig) {
    return httpApiClient.patch<{ user: User }>(`${this.url}/check-${boostType}`, config);
  }

  unlockBoostClaim(config?: AxiosRequestConfig) {
    return httpApiClient.post<string>(`${this.url}/unlock-boost-claim-request`, config);
  }

  confirmUnlockBoostClaim(config?: AxiosRequestConfig) {
    return httpApiClient.patch<{ user: User }>(`${this.url}/unlock-boost-claim`, config);
  }

  unlockAutoBot(config?: AxiosRequestConfig) {
    return httpApiClient.post<string>(`${this.url}/unlock-bot-request`, config);
  }

  confirmUnlockAutoBot(config?: AxiosRequestConfig) {
    return httpApiClient.patch<{ user: User }>(`${this.url}/unlock-bot`, config);
  }

  getUpgrades(config?: AxiosRequestConfig) {
    return httpApiClient.get<{ upgrades: Upgrade[] }>(this.url, config);
  }

  upgradesUpgradeLevel(payload: Omit<UpgradeLevelPayload, 'label'>, config?: AxiosRequestConfig) {
    return httpApiClient.patch<{ user: User }>(`${this.url}/upgrade-level`, payload, config);
  }
}

export const boostersApi = new BoostersApi('/boost');
export const upgradesApi = new BoostersApi('/upgrade');
export const userApi = new BoostersApi('/user');
