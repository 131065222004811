import { AxiosRequestConfig } from 'axios';

import { httpApiClient } from 'shared/config/http-client.configs';
import { LoginPayload } from 'shared/types/auth.interfaces';

export class AuthApi {
  constructor(private url: string) {}

  loginUser(payload: LoginPayload, config?: AxiosRequestConfig) {
    return httpApiClient.post<{ accessToken: string }>(`/auth/login`, payload, config);
  }
}

export const authApi = new AuthApi('/auth');
