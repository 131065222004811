import { UserCard } from 'entities/user';
import { SettingsBlock } from 'features/person';
import { getTelegramApi, selectUser, useAppSelector } from 'shared';

export const Person = () => {
  const user = useAppSelector(selectUser);

  const tg = getTelegramApi();

  tg.BackButton.isVisible = true;

  if (!user) return null;

  return (
    <div className="flex flex-col flex-1 overflow-x-hidden overflow-y-auto remove-scrollbar">
      <UserCard userName={user.username ?? user.first_name} userImageUrl={user.avatar} />
      <SettingsBlock />
    </div>
  );
};
