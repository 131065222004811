import { ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

interface Props {
  title: string;
  titleClassName?: string;
  blockClassName?: string;
  children: ReactNode;
}

export const WithTitleBlock = ({ title, titleClassName, blockClassName, children }: Props) => {
  return (
    <div className={twMerge('flex flex-col', blockClassName)}>
      <h5 className={twMerge('text-lg font-medium text-white', titleClassName)}>{title}</h5>
      <div className="flex flex-col gap-1.5 mt-4">{children}</div>
    </div>
  );
};
