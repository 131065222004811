import { createSlice } from '@reduxjs/toolkit';

import { BONUSES_SLICE_NAME } from './action';
import { getBonusesReducer } from './reducers';
import { Bonus, BonusCategory } from '../../types/bonus.interfaces';

export interface BonusesSliceState {
  isLoading: boolean;
  bonus: Bonus | null;
  bonuses: BonusCategory[] | null;
}

export const initialState: BonusesSliceState = {
  bonus: null,
  bonuses: null,
  isLoading: false
};

const bonusesSlice = createSlice({
  name: BONUSES_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getBonusesReducer(builder);
  }
});

export const bonusReducer = bonusesSlice.reducer;
