import Skeleton from 'react-loading-skeleton';

import 'react-loading-skeleton/dist/skeleton.css';

export const SkeletonLeaderCard = () => {
  return (
    <div className="bg-[#282B30] rounded-xl flex px-4 py-1 gap-3">
      <Skeleton circle highlightColor="#9198a2" baseColor="#727780" width="36px" height="36px" />
      <div className="flex-1">
        <Skeleton highlightColor="#9198a2" baseColor="#727780" width="50%" />
        <Skeleton highlightColor="#9198a2" baseColor="#727780" width="25%" height="12px" />
      </div>
      <Skeleton highlightColor="#9198a2" baseColor="#727780" width="36px" height="36px" />
    </div>
  );
};
