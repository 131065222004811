import React from 'react';

import { ReactComponent as CalendarIcon } from 'assets/svg/calendar.svg';

import { User } from '../../../shared';

interface Props {
  user: User;
  handleClick: VoidFunction; // TODO - add actions props with onClick, id, label, etc...
}
// TODO - map actions below
export const ActionsBar = ({ user, handleClick }: Props) => {
  return (
    <div className="flex">
      <button
        onClick={handleClick}
        className="aspect-square flex flex-col justify-center items-center p-2 bg-[#282B30] rounded-3xl text-sm font-semibold"
      >
        <CalendarIcon />
        <p className="mt-1">Daily reward</p>
        <p className="font-bold text-[#858994]">{user?.daily_counter + 1}/20</p>
      </button>
    </div>
  );
};
