import { useState, useEffect } from 'react';

import { add, differenceInMilliseconds, parseISO } from 'date-fns';

export const useTimer = (
  time: string | undefined,
  hours: number,
  minutes: number,
  second: number
): [string, boolean, number] => {
  const [timeLeft, setTimeLeft] = useState<string>('00:00:00');
  const [isCalcTime, setIsCalcTime] = useState<boolean>(false);
  const [secondsLeft, setSecondsLeft] = useState(0);

  const checkFormatTime = (value: number) => {
    return String(value).padStart(2, '0');
  };

  useEffect(() => {
    if (!time) {
      setTimeLeft('00:00:00');

      setIsCalcTime(false);

      return;
    }

    const targetDate = add(parseISO(time), { hours, minutes, seconds: second });
    const timer = setInterval(() => {
      const now = new Date();

      const differenceInMs = differenceInMilliseconds(targetDate, now);
      const differenceInSeconds = Math.floor(differenceInMs / 1000);

      const hours = checkFormatTime(Math.floor(differenceInSeconds / 3600));
      const minutes = checkFormatTime(Math.floor((differenceInSeconds % 3600) / 60));
      const seconds = checkFormatTime(Math.floor(differenceInSeconds % 60));

      setTimeLeft(`${hours}:${minutes}:${seconds}`);
      setSecondsLeft(differenceInSeconds);

      if (differenceInSeconds <= 0) {
        clearInterval(timer);
        setIsCalcTime(false);
        setTimeLeft('00:00:00');

        return;
      }

      setIsCalcTime(true);
    }, 1000);

    return () => clearInterval(timer);
  }, [hours, minutes, second, time]);

  return [timeLeft, isCalcTime, secondsLeft];
};
