import { useEffect, useState } from 'react';

import { differenceInMilliseconds, parseISO } from 'date-fns';

import { useAppSelector, selectUser, selectServerTime } from 'shared';

const CLAIM_INCREASE_RATE = 1250;
const CLAIM_PERIOD_HOURS = 4;
const MAX_CLAIM = 1250;

export const useAvailableClaim = () => {
  const [available, setAvailable] = useState(0);

  const user = useAppSelector(selectUser);
  const serverTime = useAppSelector(selectServerTime);

  useEffect(() => {
    if (!serverTime || !user?.last_claim) return;
    const serverTimeDate = parseISO(serverTime);
    const lastClaimDate = parseISO(user?.last_claim);

    const differenceInMs = differenceInMilliseconds(serverTimeDate, lastClaimDate);
    const differenceInHours = differenceInMs / (1000 * 60 * 60);

    const fullPeriods = Math.floor(differenceInHours / CLAIM_PERIOD_HOURS);
    const remainingHours = differenceInHours % CLAIM_PERIOD_HOURS;
    const proportionateIncrease = (remainingHours / CLAIM_PERIOD_HOURS) * CLAIM_INCREASE_RATE;

    let availableClaim = fullPeriods * CLAIM_INCREASE_RATE + proportionateIncrease;

    if (availableClaim > MAX_CLAIM) {
      availableClaim = MAX_CLAIM;
    }

    setAvailable(Math.floor(availableClaim));
  }, [serverTime, user]);

  return available > 0 ? parseFloat(available.toFixed(2)) : 0;
};
