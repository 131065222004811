export enum Direction {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  UP = 'FORWARD',
  DOWN = 'BACKWARD'
}

export const SPEED = 10;

export const WINDOW_SIZE = window.innerWidth;

export const CANVAS_SIZE = Math.floor(WINDOW_SIZE - 54);

export const ITEM_SIZE = Math.floor(CANVAS_SIZE / 20);

export const SNAKE_INIT = [
  {
    x: 0,
    y: 0
  },
  {
    x: ITEM_SIZE,
    y: 0
  }
];
